import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './services/auth/auth.guard';

export const rootRouterConfig: Routes = [
    {
        path: '',
        redirectTo: 'hub',
        pathMatch: 'full',
        canActivate: [AuthGuard],
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: 'sessions',
                loadChildren: () => import('./sessions/sessions.module').then((m) => m.SessionsModule),
                data: { title: 'Session' },
            },
        ],
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'hub',
                loadChildren: () => import('./hub/hub.module').then((m) => m.HubModule),
                data: { title: 'Hub', breadcrumb: 'HUB' },
            },
            {
                path: 'user',
                loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
                data: { title: 'Profile', breadcrumb: 'PROFILE' },
            },
            {
                path: 'video-conference',
                loadChildren: () =>
                    import('./video-conference/video-conference.module').then((m) => m.VideoConferenceModule),
                data: { title: 'vCME LIVE', breadcrumb: 'vCME LIVE' },
            },
            {
                path: 'admin',
                loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
                data: { title: 'Admin', breadcrumb: 'Admin' },
                canActivate: [AuthGuard],
            },
            {
                path: 'email',
                loadChildren: () => import('./email/email.module').then((m) => m.EmailModule),
                data: { title: 'Email', breadcrumb: 'Email' },
                canActivate: [AuthGuard],
            },
            {
                path: 'cpd',
                loadChildren: () => import('./cpd/cpd.module').then((m) => m.CpdModule),
                data: { title: 'My CPD Certificates', breadcrumb: 'All Certificates' },
                canActivate: [AuthGuard],
            },
            {
                path: 'legal',
                loadChildren: () => import('./legal-docs/legal-docs.module').then((m) => m.LegalDocsModule),
                data: { title: 'Legal', breadcrumb: 'legal' },
                canActivate: [AuthGuard],
            },
        ],
    },
    {
        path: '**',
        redirectTo: '/sessions/signin',
    },
];
