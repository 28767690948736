// import { Doctor } from './doctor.model';
import { CertificateBackground } from './certificate-background.model';
import { Presenter } from './presenter.model';

export class Answer {
    answerId: string;
    answerText: string;
    checked: boolean;
}

export enum QuestionType {
    SINGLE_ANSWER,
    MULTIPLE_ANSWERS,
    FREE_TEXT,
    YES_NO,
}

export class Question {
    questionTitle: string;
    questionId: string;
    userAnswer: number; // id of correct answer
    userAnswers: number[];
    correctAnswers: string[];
    correctAnswer: string;
    answers: Answer[];
    questionType: QuestionType;
}

export class Questionnaire {
    questionnaireId: string;
    videoId: number;
    questions: Question[];
    title: string;
    videoUrl: string;
    activityTopic: string;
    levelOfActivity: string;
    level1: boolean;
    level2: boolean;
    ethics: boolean;
    dateOfActivity: Date;
    accreditationNumber: string;
    certificateBackground: CertificateBackground;
    // linkedUsers: Doctor[];
    markets: string[];
    hcpTypes: string[];
    isActive: boolean;
    presenter: Presenter;
    presenter2: Presenter;
    cpdPointAccreditation: string;
    accreditationType: string;
    videoLength: string;
    expiryDate: Date;
    passRate: number;
    areasOfInterest: string[];
    tags: string;
    activityType: string;
    webinarUrl: string;
    webinarStartDateTime: string;
    activityThumbnailUrl: string;
    questionnaireStatus: string;
    sortOrder: number;
    newFlag: boolean;
    hasCertificate: boolean;
}

export class CompletedQuestionnaire {
    completedBy: string;
    questionnaireId: string;
    dateCompleted: Date;
    score: number;
    market: string;
    fullName: string;
    completedQuestionnaireId: string;
}

export class SavedResponse {
    questionnaireId: string;
    userId: string;
    completedForm: any;
}
