<div class="content">
    <p><strong>Adverse Event Reports:</strong></p>
    <p>
        If you have an adverse event to report or have a medical information query for an Upjohn product please
        call&nbsp;
    </p>
    <p>
        0860 734 937 or send an email to:&nbsp;<a href="mailto:ZAF.AEReporting@pfizer.com"
            >ZAF.AEReporting@pfizer.com</a
        >
    </p>
    <p><strong>Corporate Contact Info:</strong></p>
    <p>
        <strong>Physical Address</strong><br />
        85 Bute Lane<br />
        Sandton<br />
        2146
    </p>
    <p>
        <strong>Postal Address</strong><br />
        Upjohn South Africa (Pty) Ltd
    </p>
    <p>
        P.O. Box 783720<br />
        Sandton<br />
        2196
    </p>
    <p>Tel: +27 11 320 6000</p>

    <div mat-dialog-actions>
        <button mat-raised-button class="mat-primary mb-1" (click)="close()">Close</button>
    </div>
</div>
