import { Component, OnInit, Inject, Input } from '@angular/core';
import { Presenter } from 'app/models/presenter.model';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Questionnaire } from 'app/models/questionnaire.model';
import { Router } from '@angular/router';
import { CardBioComponent } from '../card-bio/card-bio.component';
import { egretAnimations } from 'app/shared/animations/egret-animations';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    animations: egretAnimations,
})
export class CardComponent implements OnInit {
    // presenter: Presenter;
    @Input() questionnaire: Questionnaire;
    @Input() market: any;
    quizzIcon = 'assets/images/quizz.jpg';
    constructor(private router: Router, private dialog: MatDialog) {}

    ngOnInit() {
        // this.presenter = this.data;
        // console.log(this.data);
    }

    openVideo(questionnaire: Questionnaire) {
        this.router.navigate(['cpd/video'], { state: questionnaire });
    }

    openDialog(presenter: Presenter) {
        const dialogRef: MatDialogRef<any> = this.dialog.open(CardBioComponent, {
            width: '500px',
            maxHeight: '70vh',
            disableClose: false,
            autoFocus: false,
            data: presenter,
        });
    }

    getVideoDuration(question: Questionnaire) {
        const myVideoPlayer = document.getElementById(question.questionnaireId) as HTMLVideoElement;
        if (myVideoPlayer) {
            const duration = myVideoPlayer.duration;
            const h = Math.floor(duration / 3600),
                m = Math.floor((duration % 3600) / 60),
                s = Math.floor(duration % 60);
            if (isNaN(h)) {
                return '';
            } else {
                return h.toFixed(0) + 'h :' + m.toFixed(0) + 'm :' + s.toFixed(0) + 's';
            }
        }
    }

    getActivityColor(activitStatus, background = true): string {
        switch (activitStatus) {
            case 'Attend':
                return background ? 'attend-color-background' : 'attend-color';
                break;
            case 'Completed':
                return background ? 'completed-color-background' : 'completed-color';
                break;
            case 'In-Progress':
                return background ? 'in-progress-color-background' : 'in-progress-color';
                break;
        }
    }

    closeToExpire(expiryDate) {
        if (expiryDate) {
            if (this.daysToExpire(expiryDate) < 30) {
                return 'about-to-expire';
            }
        }
        return '';
    }

    daysToExpire(expiryDate: Date): number {
        const today = new Date();
        const endDate = new Date(expiryDate);
        return Math.floor(
            (Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) -
                Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())) /
                (1000 * 60 * 60 * 24)
        );
    }

    getExpiredStatus(status: string, expiryDate: Date): string {
        if (status === 'Attend' && this.daysToExpire(expiryDate) < 0) {
            return 'Expired';
        }
        return status;
    }

    showCpdPoints(points) {
        switch (this.market) {
            case 'India':
                return false;
                break;
            case 'Pakistan':
                return false;
                break;
        }
        if (points === '0' || points === '' || !points) {
            return false;
        }
        return true;
    }
}
