import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { of, Observable } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { map } from 'rxjs/operators/map';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserProfileService } from './user-profile.service';
import { UserProfile } from 'app/models/user-profile.model';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';

@Injectable()
export class AuthService {
    userProfile: UserProfile;
    userProfile$: Observable<UserProfile>;
    isLoggedIn$: Observable<boolean>;
    isLoggedIn: boolean;
    mpNumber: string;
    currentUser$: Observable<any>;
    private currentUser: any;
    found = false;

    // mpDetail;
    constructor(
        private router: Router,
        public afAuth: AngularFireAuth,
        private snackBar: MatSnackBar,
        private db: AngularFirestore,
        private userProfileService: UserProfileService,
        // private route: ActivatedRoute, Need this to redirect to url after login, this is not done as yet
        public ngZone: NgZone // NgZone service to remove outside scope warning
    ) {
        // this.fetchUserProfile();
        // this.fetchCurrentUser();
        this.currentUser$ = this.afAuth.user;
        this.isLoggedIn$ = this.afAuth.authState.pipe(map((user) => !!user));
        // this.getIsLoggedIn();
        // afAuth.authState.subscribe((user) => (this.currentUser = user));
    }

    // getIsLoggedIn() {
    //   this.afAuth.authState.subscribe( auth => {
    //     if (auth!=null) {
    //       this.isLoggedIn=true;
    //     }
    //   })
    // }

    // public fetchCurrentUser() {
    //   this.currentUser$ = this.afAuth.user;

    //   this.afAuth.user.subscribe(user => {
    //     this.currentUser = user;
    //     if (user) {
    //       this.userProfile$ = this.db.doc<UserProfile>(`userProfile/${this.currentUser.uid}`).valueChanges();
    //     }
    //     // this.currentUserChanged.next(user);
    //   })

    //   // console.log("this.fetchCurrentUser");
    //   // console.log(this.currentUser$);
    // }

    authListener() {
        if (this.afAuth.user) {
            this.afAuth.user.subscribe((user) => {
                if (user) {
                    this.isLoggedIn = true;
                    // console.log('authListener: logged in');
                    this.currentUser = user;
                    this.userProfile$ = this.db.doc<UserProfile>(`userProfile/${this.currentUser.uid}`).valueChanges();
                    // this.userProfileService.fetchUserProfile(this.currentUser);
                    // Verify Email
                    if (!this.emailVerified()) {
                        // console.log('redirect TO VERIFY EMAIL')
                        this.router.navigate(['/sessions/verify-email']);
                    } else {
                        this.checkIfUserHasProfileAndRedirect();
                    }
                } else {
                    this.currentUser = null;
                    // this.router.navigate(['/sessions/signin']);
                }
            });
        }
    }

    emailVerified(): boolean {
        // console.log(this.afAuth.auth.currentUser.emailVerified);
        if (this.afAuth.auth.currentUser.emailVerified) {
            // console.log("Success: Email Verified")
        } else {
            this.handleError('Email not Verfied!');
        }
        return this.afAuth.auth.currentUser.emailVerified;
    }

    signInWithEmailAndPassword(email: string, password: string) {
        this.afAuth.auth
            .signInWithEmailAndPassword(email, password)
            .then((result) => {
                this.currentUser = result;
            })
            .catch((error) => {
                window.alert(error.message);
                console.log('Something is wrong:', error.message);
            });
    }

    sendVerificationMail() {
        return this.afAuth.auth.currentUser.sendEmailVerification().then(() => {
            this.router.navigate(['/sessions/verify-email']);
            this.handleError('A verification email was sent to: ' + this.afAuth.auth.currentUser.email);
        });
    }

    // async googleSignIn() {
    //   const provider = new auth.GoogleAuthProvider();
    //   const credential = await this.afAuth.auth.signInWithPopup(provider);
    //   return this.updateUser(credential.user, null);
    // }

    sendPasswordResetEmail(email: string) {
        return this.afAuth.auth
            .sendPasswordResetEmail(email)
            .then(() => this.handleError('We have emailed you a password rest email to: ' + email))
            .catch((error) => this.handleError(error.message));
    }

    emailSignUp(email: string, password: string): Observable<boolean> {
        this.afAuth.auth
            .createUserWithEmailAndPassword(email, password)
            .then(() => {
                this.sendVerificationMail();
                return of(true);
            })
            .catch((error) => {
                switch (error.code) {
                    case 'auth/email-already-in-use':
                        this.handleError(`Email address ${email} already in use.`);
                        break;
                    case 'auth/invalid-email':
                        this.handleError(`Email address ${email} is invalid.`);
                        break;
                    case 'auth/operation-not-allowed':
                        this.handleError(`Error during sign up.`);
                        break;
                    case 'auth/weak-password':
                        this.handleError(
                            'Password is not strong enough. Add additional characters including special characters and numbers.'
                        );
                        break;
                    default:
                        this.handleError(error.message);
                }
            });
        return of(false);
    }

    registerUserProfile(mpNumber: string, market: string, governmentOffical: string, hcpType: string) {
        if (mpNumber !== '' && hcpType === 'Doctor') {
            // if (this.isMpNumberisInUse(mpNumber)) {
            //     this.handleError(mpNumber + ' has been registered!');
            // } else {
            this.getDoctorsDetailsAndUpdateUserProfile(mpNumber, market, governmentOffical, hcpType);
            // }
        } else {
            this.userProfileService
                .createEmptyUserProfile(this.currentUser, mpNumber, market, governmentOffical, hcpType)
                .then(() => {
                    this.router.navigate(['user/profile']);
                });
        }
    }

    getDoctorsDetailsAndUpdateUserProfile(
        mpNumber: string,
        market: string,
        governmentOffical: string,
        hcpType: string
    ) {
        return this.getDoctorsDefaults(mpNumber)
            .pipe(first())
            .subscribe((docArray) => {
                if (docArray.length > 0) {
                    docArray.map((mpDetail) => {
                        this.userProfileService.createUserProfile(
                            this.currentUser,
                            mpNumber,
                            mpDetail,
                            market,
                            governmentOffical,
                            hcpType
                        );
                        this.handleError('Successfully confirmed ' + mpNumber);
                        this.router.navigate(['user/profile']);
                    });
                } else {
                    this.userProfileService.createEmptyUserProfile(
                        this.currentUser,
                        mpNumber,
                        market,
                        governmentOffical,
                        hcpType
                    );
                    this.router.navigate(['user/profile']);
                }
            }, first());
    }

    getDoctorsDefaults(mpNumber: string) {
        // Validating against the doctors database, check with MP and add trailing 0's in front
        return this.db
            .collection('doctors', (ref) => ref.where('councilnumber1', '==', mpNumber + 'MP'))
            .valueChanges();
    }

    // checkIfMpNumberisInUse(mpNumber: string) {
    //     // going to replace
    //     return this.db.collection('userProfile', (ref) => ref.where('mpNumber', '==', mpNumber)).valueChanges();
    // }

    isMpNumberisInUse(mpNumber: string, hcpType: string) {
        return this.db
            .collection('userProfile', (ref) => ref.where('mpNumber', '==', mpNumber).where('hcpType', '==', hcpType))
            .valueChanges()
            .pipe(first());
    }

    checkIfUserHasProfileAndRedirect() {
        // let found: boolean;
        // let userProfile;
        this.db
            .collection('userProfile', (ref) => ref.where('uid', '==', this.currentUser.uid))
            .valueChanges()
            .pipe(first())
            .subscribe((userProfile) => {
                if (userProfile.length > 0) {
                    this.router.navigate(['user/profile']);
                } else {
                    this.router.navigate(['/sessions/verify-mp-number']);
                }
            });
    }

    async signOut() {
        await this.afAuth.auth
            .signOut()
            .then(() => {
                this.handleError('You have been successfully logged out');
                // window.location.reload();
                this.router.navigate(['sessions/signin']);
            })
            .catch((error) => {
                console.log('logout error:', error);
            });
    }

    getPrefixForHcpType(hcpType) {
        switch (hcpType) {
            case 'Nurse':
                return 'N';
                break;
            case 'Pharmacist':
                return 'P';
                break;
            default:
                return 'MP';
                break;
        }
    }

    getPlaceholderForHcpType(hcpType) {
        switch (hcpType) {
            case 'Nurse':
                return ' (SANC Number)';
                break;
            case 'Pharmacist':
                return ' (SAPC Number)';
                break;
            default:
                return ' (HPCSA Number)';
                break;
        }
    }

    public handleError(error) {
        this.snackBar.open(error, 'close', { duration: 6000, panelClass: 'red-snackbar' });
    }
}
