import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService } from './auth.service'
import { Observable } from 'rxjs/internal/Observable';
import { map, take, tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  // canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
  //   // console.log("IsLoggedIn:",this.authService.isLoggedIn);
  // //   if (this.authService.isLoggedIn) {
  // //     return true;
  // //   } else {
  // //     this.router.navigate(['/sessions/signin'], { queryParams: { returnUrl: state.url } });
  // //   }
  // // }
  // return this.authService.afAuth.authState
  //           .take(1)
  //           .map(authState => !!authState)
  //           .do(authenticated => {
  //             if (!authenticated) {
  //                 this.router.navigate(['admin', 'sign-in']);
  //             }
  //           });
  // }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.afAuth.authState.pipe(
      take(1),
      map((authState) => !!authState),
      tap(authenticated => {
        if (!authenticated) { this.router.navigate(['/sessions/signin']) }
      })
    )
  }
}
