import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// SERVICES
import { ThemeService } from 'app/services/theme.service';
import { NavigationService } from 'app/services/navigation.service';
import { RoutePartsService } from 'app/services/route-parts.service';
import { AuthGuard } from 'app/services/auth/auth.guard';
import { AppConfirmService } from 'app/services/app-confirm/app-confirm.service';
import { AppLoaderService } from 'app/services/app-loader/app-loader.service';

import { SharedComponentsModule } from './components/shared-components.module';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import { CpdCertificateService } from 'app/services/cpd-certificate.service';
import { QuestionnaireAdminService } from '../services/questionnaire-admin.service';

@NgModule({
    imports: [CommonModule, SharedComponentsModule, SharedDirectivesModule],
    providers: [
        ThemeService,
        NavigationService,
        RoutePartsService,
        AuthGuard,
        AppConfirmService,
        AppLoaderService,
        CpdCertificateService,
        QuestionnaireAdminService,
    ],
    declarations: [],
    exports: [ SharedComponentsModule, SharedDirectivesModule],
})
export class SharedModule {}
