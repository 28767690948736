import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { of } from 'rxjs';
import { UserProfile } from 'app/models/user-profile.model';
import { UserProfileService } from './auth/user-profile.service';

@Injectable({
    providedIn: 'root',
})
export class HcpTypeService {
    hcpTypes = [{ name: 'Doctor' }, { name: 'Nurse' }, { name: 'Pharmacist' }, { name: 'Viatris Staff' }];
    constructor() {}

    getHcpTypes() {
        // return this.db.collection(`hcpTypes`, (m) => m.where('isActive', '==', true)).valueChanges();
        return of(this.hcpTypes);
    }
}
