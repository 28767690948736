<mat-card
    class="card-container"
    *ngIf="cpdCertificate"
    [@animate]="{ value: '*', params: { scale: '.9', delay: '300ms' } }"
>
    <mat-card-content class="card-content">
        <div class="card-title mat-display-1" [innerHtml]="cpdCertificate.title | safe: 'html'"></div>

        <img class="card-thumbnail" src="./../../../assets/images/certificate-thumbnail.png" alt="" />

        <div class="card-download-section">
            <div class="date"><b>Date awarded:</b> {{ cpdCertificate.dateObtained | date: 'EEEE dd MMM yyy' }}</div>
            <button mat-raised-button color="primary" (click)="onDownload(cpdCertificate)">Download Certificate</button>
        </div>
    </mat-card-content>
</mat-card>
