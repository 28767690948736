<div class="content">
    <!-- Left Side content -->
    <div fxLayout="column" fxLayoutAlign="center center" class="signup4-header background-color" fxFlex="40">
        <div class="" fxLayout="row wrap" fxLayoutAlign="center center">
            <img width="200px" src="assets/images/illustrations/agreement.svg" alt="" />
            <span class="mat-display-3">Technical Assistance</span>
        </div>
    </div>

    <!-- Right side content -->
    <div fxFlex="60" fxLayout="row wrap" fxLayoutAlign="center center">
        <div class="session-form-hold">
            <div class="text-center pt-8 pb-16">
                <!-- <img width="250px" src="assets/images/logo.png" alt="" /> -->
                <br />
                <img width="250px" src="assets/images/viatrus-horiz.jpg" alt="" class="mb-07 mt-10" />
                <div class="mat-display-2">Contact Viatris</div>
                <div class="mat-display-1">+27 11 451 1300</div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="button-right">
        <button mat-raised-button class="mat-primary mb-1" (click)="close()">Close</button>
    </div>
</div>
