import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AdverseEventReportingComponent } from 'app/legal-docs/adverse-event-reporting/adverse-event-reporting.component';
import { TermsOfUseComponent } from 'app/legal-docs/terms-of-use/terms-of-use.component';
import { TermsOfUseNigeriaComponent } from 'app/legal-docs/terms-of-use-nigeria/terms-of-use-nigeria.component';
import { PrivacyPolicyComponent } from 'app/legal-docs/privacy-policy/privacy-policy.component';
import { DisclaimerComponent } from 'app/legal-docs/disclaimer/disclaimer.component';
import { TechnicalAssistanceComponent } from 'app/legal-docs/technical-assistance/technical-assistance.component';
import { AuthService } from 'app/services/auth/auth.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    market: string;

    constructor(private dialog: MatDialog, private authService: AuthService) {}

    ngOnInit() {
        this.getUserProfile();
    }

    getUserProfile() {
        if (this.authService.userProfile$) {
            this.authService.userProfile$.subscribe((userProfile) => {
                this.market = userProfile.market;
            });
        }
    }

    openAdverseEventReporting() {
        if (this.market === 'South Africa') {
            this.openDialog(AdverseEventReportingComponent);
        } else {
            window.open('https://www.pfizermedicalinformationng.com/', '_blank');
        }
    }

    openTermsOfUse() {
        if (this.market === 'South Africa') {
            this.openDialog(TermsOfUseComponent);
        } else {
            this.openDialog(TermsOfUseNigeriaComponent);
        }
    }

    openPrivacyPolicy() {
        this.openDialog(PrivacyPolicyComponent);
    }

    openDisclaimer() {
        this.openDialog(DisclaimerComponent);
    }

    openTechnicalAssistance() {
        this.openDialog(TechnicalAssistanceComponent);
    }

    openDialog(dialogComponent) {
        const dialogRef: MatDialogRef<any> = this.dialog.open(dialogComponent, {
            height: '70%',
            width: '900px',
            disableClose: false,
            autoFocus: false,
        });
    }
}
