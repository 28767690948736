<div class="content">
    <h1 mat-dialog-title>Terms of use</h1>
    <!-- <div mat-dialog-content> -->
    <p>
        1. <strong>Use of Web site.</strong> The information provided on this site is for general informational and
        educational purposes. Certain sections of this Web site are intended for particular audiences including Pfizer's
        employees, customers and shareholders, as well as members of the health care community and the general public.
        Your access to and use of the information contained in the Web site is subject to this Terms of Use Agreement.
        By accessing and using this Web site, you accept, without limitation or qualification, this Terms of Use
        Agreement.1. Use of Web site. The information provided on this site is for general informational and educational
        purposes. Certain sections of this Web site are intended for particular audiences including Pfizer's employees,
        customers and shareholders, as well as members of the health care community and the general public. Your access
        to and use of the information contained in the Web site is subject to this Terms of Use Agreement. By accessing
        and using this Web site, you accept, without limitation or qualification, this Terms of Use Agreement.
    </p>

    <p>
        2. <strong>Content.</strong> Pfizer will use reasonable efforts to include accurate and up-to-date information
        on this Web site but makes no warranties or representations of any kind as to its accuracy, currency or
        completeness. You agree that access to and use of this Web site and the content thereof is at your own risk.
        Pfizer disclaims all warranties, express or implied, including warranties of merchantability or fitness for a
        particular purpose. Neither Pfizer nor any party involved in creating, producing or delivering this Web site
        shall be liable for any damages, including without limitation, direct, incidental, consequential, indirect or
        punitive damages, arising out of access to, use of or inability to use this Web site, or any errors or omissions
        in the content thereof. This limitation includes damages to, or for any viruses that infect, your computer
        equipment.
    </p>

    <p>
        3. <strong>Indemnification.</strong> You agree to indemnify, defend and hold harmless Pfizer, its officers,
        directors, employees, agents, suppliers and third party partners from and against all losses, expenses, damages
        and costs, including reasonable attorneys' fees, resulting from any violation by you of these Terms of Use.
    </p>

    <p>
        4. <strong>Privacy.</strong> Pfizer respects the privacy of its Web site users. Please refer to Pfizer's Privacy
        Policy that explains users' rights and responsibilities with respect to information that is disclosed on this
        Web site.
    </p>

    <p>
        5. <strong>Third Party Web sites and Links.</strong> This Web site may contain links or references to other Web
        sites maintained by third parties over whom Pfizer has no control. Such links are provided merely as a
        convenience. Similarly, this Web site may be accessed from third party links over whom Pfizer has no control.
        Pfizer makes no warranties or representations of any kind as to the accuracy, currency, or completeness of any
        information contained in such Web sites and shall have no liability for any damages or injuries of any kind
        arising from such content or information. Inclusion of any third party link does not imply an endorsement or
        recommendation by Pfizer.
    </p>

    <p>
        Also, please be aware that the security and privacy policies on these sites may be different than Pfizer's
        policies, so please read third party privacy and security policies closely.
    </p>

    <p>
        If you have any questions or concerns about the products and services offered on linked third party websites,
        please contact the third party directly.
    </p>

    <p>
        6. <strong>Medical Information.</strong> This Web site may contain general information relating to various
        medical conditions and their treatment. Such information is provided for informational purposes only and is not
        meant to be a substitute for advice provided by a doctor or other qualified health care professional. Web site
        viewers should not use the information contained herein for diagnosing a health or fitness problem or disease.
        Patients should always consult with a doctor or other health care professional for medical advice or information
        about diagnosis and treatment and healthcare providers should make a diagnosis considering the unique
        characteristics of the patient.
    </p>

    <p>
        7. <strong>Forward Looking Statements.</strong> This Web site may contain forward-looking statements about
        Pfizer's financial and operating performance, business plans and prospects, in-line products and products in
        development that involve substantial risks and uncertainties. Actual results could differ materially from the
        expectations and projections set forth in those statements. Such risks and uncertainties include, among other
        things, the uncertainties inherent in pharmaceutical research and development; decisions by regulatory
        authorities regarding whether and when to approve drug applications and supplemental drug applications as well
        as their decisions regarding labeling and other matters that could affect the availability or commercial
        potential of Pfizer products; competitive developments; the ability to successfully market both new and existing
        products; challenges to the validity and enforcement of Pfizer's patents; trends toward managed care and health
        care cost containment; governmental laws and regulations affecting health care, including without limitation
        regarding pharmaceutical product access, pricing and reimbursement; and general economic conditions, such as
        interest rate and foreign currency exchange rate fluctuations. Pfizer assumes no obligation to update any
        forward-looking statements as a result of new information or future events or developments.
    </p>

    <p>
        8. <strong>Non-Confidential Information.</strong> Subject to any applicable terms and conditions set forth in
        our Privacy Policy, any communication or other material that you send to us through the Internet or post on a
        Pfizer Web site by electronic mail or otherwise, such as any questions, comments, suggestions or the like, is
        and will be deemed to be non-confidential and Pfizer shall have no obligation of any kind with respect to such
        information. Pfizer shall be free to use any ideas, concepts, know-how or techniques contained in such
        communication for any purpose whatsoever, including but not limited to, developing, manufacturing and marketing
        products.
    </p>

    <p>
        9. <strong>Trademarks.</strong> All product names, whether or not appearing in large print or with the trademark
        symbol, are trademarks of Pfizer, its affiliates, related companies or its licensors or joint venture partners,
        unless otherwise noted. The use or misuse of these trademarks or any other materials, except as permitted
        herein, is expressly prohibited and may be in violation of copyright law, trademark law, the law of slander and
        libel, the law of privacy and publicity, and communications regulations and statutes. Please be advised that
        Pfizer actively and aggressively enforces its intellectual property rights to the fullest extent of the law.
    </p>

    <p>
        10. <strong>Copyrights.</strong> The entire contents of this Web site are subject to copyright protection.
        Copyright © 2005 - 2017 Pfizer. The contents of Pfizer Web sites may not be copied other than for noncommercial
        individual reference with all copyright or other proprietary notices retained, and thereafter may not be
        recopied, reproduced or otherwise redistributed. Except as expressly provided above, you may not otherwise copy,
        display, download, distribute, modify, reproduce, republish or retransmit any information, text or documents
        contained in this Web site or any portion thereof in any electronic medium or in hard copy, or create any
        derivative work based on such images, text or documents, without the express written consent of Pfizer. Nothing
        contained herein shall be construed as conferring by implication, estoppel or otherwise any license or right
        under any patent or trademark of Pfizer, or any third party.
    </p>

    <p>
        11. <strong>Void Where Prohibited.</strong> This Web site and its contents are intended to comply with the laws
        and regulations in the Republic of South Africa. Although the information on this Web site is accessible to
        users outside of the Republic of South Africa, the information pertaining to Pfizer products is intended for use
        only by registered healthcare professionals of the Republic of South Africa. Other countries may have laws,
        regulatory requirements and medical practices that differ from those in the Republic of South Africa. This site
        links to other sites produced by Pfizer's various operating divisions, affiliates and subsidiaries, some of
        which are outside the Republic of South Africa. Those sites may have information that is appropriate only to
        that particular originating country. Pfizer reserves the right to limit provision of its products or services to
        any person, geographic region or jurisdiction and/or to limit the quantities or any products or services we
        provide. Any offer for any product or service made on this Web site is void where prohibited.
    </p>

    <p>
        12. <strong>Governing Laws.</strong> This Terms of Use Agreement and your use of the Web site shall be governed
        by the laws of the Republic of South Africa without regard to its conflicts of laws principles. Any legal action
        or proceeding related to this Web site shall be brought exclusively in a court of competent jurisdiction sitting
        in Johannesburg, in the Republic of South Africa.
    </p>

    <p>
        13. <strong>Miscellaneous.</strong> If any provision of this Agreement is held to be unlawful, void or
        unenforceable, then such provision shall be severable without affecting the enforceability of all remaining
        provisions. Pfizer reserves the right to alter or delete materials from this Web site at any time at its
        discretion.
    </p>
    <br />
    <p>
        Copyright © 2017 Pfizer. All rights reserved.
    </p>

    <p>
        The information provided in this site is intended for registered healthcare professionals of the Republic of
        South Africa only.
    </p>
    <div mat-dialog-actions>
        <button mat-raised-button class="mat-primary mb-1" (click)="close()">I accept</button>
    </div>
</div>
<!-- </div> -->
