<footer class="footer">
    <!-- <a mat-raised-button color="accent" href="https://themeforest.net/item/egret-angular-4-material-design-admin-template/20161805">Buy Egret</a>-->
    <!-- <span class="m-auto"></span> -->
    <!-- <a href="https://www.dilowa.co.za">Designed & Developed by Dilowa</a> -->
    <!-- <ul
        appDropdown
        class="sidenav"
        style="position: absolute; bottom: 0; padding-bottom: 10px; overflow: scroll; font-style: italic;"
    >
        <li>
            <div matRipple id="areaTermsAndConditions">
                <mat-icon></mat-icon> -->
    <!-- <a class="mat-raised-button main-footer-padding" (click)="openAdverseEventReporting()">Adverse Event Reporting</a>

    <a *ngIf="market == 'South Africa'" class="mat-raised-button main-footer-padding" (click)="openDisclaimer()"
        >Disclaimer</a
    >

    <a *ngIf="market == 'South Africa'" class="mat-raised-button main-footer-padding" (click)="openPrivacyPolicy()"
        >Privacy Policy</a
    >

    <a class="mat-raised-button main-footer-padding" (click)="openTermsOfUse()">Terms of Use</a>

    <a class="mat-raised-button main-footer-padding" (click)="openTechnicalAssistance()">Technical Assistance</a> -->

    <div>
        Copyright © 2020 - Jade Elephant
        <span fxHide.lt-sm="true" class="mat-body-1">(<b>Release:</b>23 June 2021)</span>
    </div>
</footer>
