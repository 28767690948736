import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, Query } from '@angular/fire/firestore';
import { first, map, take } from 'rxjs/operators';
import { convertSnaps } from '../shared/helpers/db-utils';
import { Questionnaire } from 'app/models/questionnaire.model';
import { CpdCertificate } from 'app/models/cpd-certificate.model';
import * as jsPDF from 'jspdf';
import * as firebase from 'firebase/app';
import { QuestionnaireService } from './questionnaire.service';
import { UserProfile } from 'app/models/user-profile.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CpdCertificateService {
    cpdCertificateCollection: AngularFirestoreCollection<CpdCertificate>;
    tempQuestionnairs: Questionnaire[];

    constructor(private db: AngularFirestore, private qs: QuestionnaireService) {
        this.cpdCertificateCollection = this.db.collection<CpdCertificate>('cpd-certificates');
    }

    get timestamp() {
        return firebase.firestore.FieldValue.serverTimestamp();
    }

    saveNewCertificate(cpdCertificate: CpdCertificate) {
        return new Promise<CpdCertificate>((resolve, reject) => {
            this.cpdCertificateCollection.add(JSON.parse(JSON.stringify(cpdCertificate))).then(
                (res) => {},
                (err) => reject(err)
            );
        });
    }

    updateCertificate(cpdCertificate: CpdCertificate) {
        return this.cpdCertificateCollection.doc(cpdCertificate.id).update(cpdCertificate);
    }

    getAllCerticates() {
        return this.db
            .collection('cpd-certificates')
            .snapshotChanges()
            .pipe(map((snaps) => convertSnaps<CpdCertificate[]>(snaps)));
    }

    deleteCert(id) {
        return this.db.doc(`cpd-certificates/${id}`).delete();
    }

    revokeCertificate(cpdCertificate: CpdCertificate) {
        // const timestamp = this.timestamp;
        // cpdCertificate.isDeleted = true;
        // cpdCertificate.updatedAt = new Date();
        // return this.updateCertificate(cpdCertificate);
        this.deleteCert(cpdCertificate.id);
    }

    getCertificateIfExists(questionnaire: Questionnaire, userProfile: UserProfile): Observable<CpdCertificate[]> {
        return this.db
            .collection<CpdCertificate>('cpd-certificates', (ref) => {
                let query: Query = ref;
                query = query.where('userId', '==', userProfile.uid).where('title', '==', questionnaire.title);
                return query.limit(1);
            })
            .valueChanges({ idField: 'id' });
    }

    generateCPDCertificate(questionnaire: Questionnaire, userProfile: UserProfile, certificateDate: Date = new Date()) {
        const doc = new jsPDF('p', 'mm', 'a4');

        const width = doc.internal.pageSize.getWidth();
        const height = doc.internal.pageSize.getHeight();
        const fullName = userProfile.title + ' ' + userProfile.firstName + ' ' + userProfile.lastName;

        const bgImage = new Image();
        if (questionnaire.certificateBackground) {
            console.log('image', questionnaire.certificateBackground.backgroundURL);
            bgImage.crossOrigin = 'Anonymous';
            bgImage.src = questionnaire.certificateBackground.backgroundURL;
        } else {
            bgImage.src = 'assets/images/certificate-default.jpg';
        }

        if (bgImage.src) {
            doc.addImage(bgImage, 'JPEG', 0, 0, width, height);
        }

        // subtitle
        doc.setFont('helvetica');
        doc.setFontStyle('normal');
        doc.setFontSize(14);
        doc.setTextColor(255, 255, 255);
        doc.text('This is to certify that', 105, 90, null, null, 'center');
        doc.text(`${fullName}`, 105, 105, null, null, 'center');

        doc.text(`HPCSA MP No: ${userProfile.mpNumber}`, 105, 120, null, null, 'center');

        if (questionnaire.cpdPointAccreditation !== '0') {
            doc.text('Participated in the following approved CPD activity:', 105, 135, null, null, 'center');
        } else {
            doc.text('Participated in the following approved activity:', 105, 135, null, null, 'center');
        }

        const splitTitle = doc.splitTextToSize(this.removeMarkup(questionnaire.title), 180);
        doc.text(splitTitle, 105, 150, null, null, 'center');

        doc.text('Activity Date:', 105, 165, null, null, 'center');
        doc.text(certificateDate.toDateString(), 105, 175, null, null, 'center');

        /// --->
        // thank you for participating.
        if (questionnaire.cpdPointAccreditation !== '0') {
            doc.text(`Accreditation no: ${questionnaire.accreditationNumber}`, 105, 185, null, null, 'center');

            doc.setFontSize(12);
            doc.text(
                `‘’Accredited by the SA Medical Association, any comments on the content of`,
                105,
                195,
                null,
                null,
                'center'
            );
            doc.text(
                `the presentation or CEU points application can be directed to cpd@samedical.org”`,
                105,
                200,
                null,
                null,
                'center'
            );

            doc.setFontSize(14);
            let awardedText = `CPD awarded: ${questionnaire.cpdPointAccreditation} `;
            if (questionnaire.accreditationType) {
                awardedText += `${questionnaire.accreditationType} `;
            }
            if (questionnaire.cpdPointAccreditation === '1') {
                awardedText += `point `;
            } else {
                awardedText += `points `;
            }
            if (questionnaire.passRate !== 0) {
                awardedText += `on a ${questionnaire.passRate}% pass rate`;
            }
            doc.text(awardedText, 105, 215, null, null, 'center');
        } else {
            doc.text(`Thank you for participating.`, 105, 200, null, null, 'center');
        }
        //
        const datauri = doc.output('datauristring');
        const cpdCert = new CpdCertificate();
        cpdCert.baseString = datauri;
        cpdCert.questionnaireId = questionnaire.questionnaireId;
        cpdCert.title = questionnaire.title;
        cpdCert.userId = userProfile.uid;
        cpdCert.dateObtained = certificateDate;
        cpdCert.market = userProfile.market;
        cpdCert.fullName = fullName;
        // this.downloadCertificate(doc, fullName, questionnaire.title);
        this.saveNewCertificate(cpdCert)
            .then(() => {})
            .catch((err) => {
                console.log(err);
            });
    }

    downloadCertificate(doc, fullName: string, title: string) {
        doc.save(`${fullName}-${title}_CPD.pdf`);
    }

    getUserCertificates(userProfile: UserProfile): Observable<CpdCertificate[]> {
        // this.cleanupCertificates();
        return this.db
            .collection<CpdCertificate>('cpd-certificates', (ref) => {
                let query: Query = ref;
                // query = query.where('userId', '==', userProfile.uid).where('isDeleted', '==', false).limit(1);
                query = query.where('userId', '==', userProfile.uid);
                return query.orderBy('dateObtained', 'desc');
            })
            .valueChanges({ idField: 'id' });
    }

    cleanupCertificates() {
        this.db
            .collection<CpdCertificate>('cpd-certificates', (ref) => {
                let query: Query = ref;
                return query;
            })
            .valueChanges({ idField: 'id' })
            .subscribe((c) => {
                const certificates: CpdCertificate[] = c;
                certificates.forEach((certificate: CpdCertificate) => {
                    if (certificate.isDeleted) {
                        this.deleteCert(certificate.id);
                    }
                });
            });
    }

    // cleanupCertificatesByUpdatingQuestionnairId() {
    //     console.log('Updating QuestionnaireId - starting...');
    //     this.getQuestionnairs().subscribe((c) => {
    //         this.tempQuestionnairs = c;
    //         this.db
    //             .collection<CpdCertificate>('cpd-certificates', (ref) => {
    //                 const query: Query = ref;
    //                 return query;
    //             })
    //             .valueChanges({ idField: 'id' })
    //             .subscribe((c) => {
    //                 const certificates: CpdCertificate[] = c;
    //                 certificates.forEach((certificate: CpdCertificate) => {
    //                     // console.log(certificate.id);
    //                     if (certificate.questionnaireId) {
    //                     } else {
    //                         this.updateQuestionnaireId(certificate);
    //                     }
    //                 });
    //             });
    //     });
    //     console.log('completed...');
    // }

    // updateQuestionnaireId(certificate: CpdCertificate) {
    //     this.tempQuestionnairs.forEach((q: Questionnaire) => {
    //         if (q.title === certificate.title) {
    //             certificate.questionnaireId = q.questionnaireId;
    //         }
    //     });
    //     console.log(certificate.questionnaireId);
    //     if (certificate.questionnaireId) {
    //         this.updateCertificate(certificate);
    //         console.log('updated: ', certificate.fullName);
    //     } else {
    //         console.log(certificate.title);
    //         // do not remove this.deleteCert(certificate.id);
    //     }
    // }

    getQuestionnairs() {
        return this.db
            .collection('questionnaires')
            .snapshotChanges()
            .pipe(
                map((snaps) => {
                    const questionnaires = convertSnaps<Questionnaire[]>(snaps).filter((q) => q.isActive === true);
                    return questionnaires;
                })
            );
    }

    removeMarkup(markup: string) {
        return markup.replace(/(<([^>]+)>)/gi, '');
    }
}
