import { Injectable, NgZone } from '@angular/core';
import { User } from 'firebase/app';
import { AngularFirestore, AngularFirestoreDocument, Query } from '@angular/fire/firestore';
import { of, Observable } from 'rxjs';
import { map } from 'rxjs/operators/map';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserProfile } from 'app/models/user-profile.model';
import { convertSnaps } from 'app/shared/helpers/db-utils';

@Injectable()
export class UserProfileService {
    userProfile: UserProfile;
    // userProfile$: Observable<UserProfile>;

    constructor(private snackBar: MatSnackBar, private db: AngularFirestore) {}

    public fetchUserProfile(user: User) {
        if (user) {
            return this.db.doc<UserProfile>(`userProfile/${user.uid}`).valueChanges();
        } else {
            return of(null);
        }
    }

    updateUserProfile(userProfile: UserProfile) {
        if (userProfile.uid) {
            return this.db.doc('userProfile/' + userProfile.uid).update(userProfile);
        }
        return null;
    }

    createUserProfile(
        user: User,
        mpNumber: string,
        mpDetail,
        market: string,
        governmentOffical: string,
        hcpType: string
    ) {
        const userProfileRef: AngularFirestoreDocument<UserProfile> = this.db.doc(`userProfile/${user.uid}`);
        const userProfile: UserProfile = {
            uid: user.uid,
            isActive: true,
            isSuspended: false,
            title: mpDetail.title,
            firstName: mpDetail.firstname,
            lastName: mpDetail.lastname,
            initials: mpDetail.initials,
            mpNumber: mpNumber,
            photoURL: '',
            gender: mpDetail.gender,
            email: user.email,
            country: mpDetail.country_description,
            language: mpDetail.language,
            town: mpDetail.town,
            physicalAddress1: mpDetail.physad1,
            physicalAddress2: mpDetail.physad2,
            physicalSuburb: mpDetail.physsuburb,
            physicalAddressCode: mpDetail.physcode,
            regionName: mpDetail.regionname,
            hcpType: hcpType,
            discipline: mpDetail.service,
            subRegionName: mpDetail.subregionname,
            cellNumber: mpDetail.tel1code + ' ' + mpDetail.tel1,
            phoneNumber: mpDetail.tel2code + ' ' + mpDetail.tel2,
            market: market,
            governmentOfficial: governmentOffical,
            healthCareProfessional: true,
            agreetoTermsOfUse: true,
            userRole: 'User',
        };
        return userProfileRef.set(userProfile, { merge: true });
    }

    createEmptyUserProfile(user: User, mpNumber: string, market: string, governmentOffical, hcpType) {
        const userProfileRef: AngularFirestoreDocument<UserProfile> = this.db.doc(`userProfile/${user.uid}`);
        const userProfile: UserProfile = {
            uid: user.uid,
            isActive: true,
            isSuspended: false,
            title: '',
            firstName: '',
            lastName: '',
            initials: '',
            mpNumber: mpNumber ? mpNumber : 'NA',
            photoURL: '',
            gender: '',
            email: user.email,
            country: market,
            language: 'English',
            town: '',
            physicalAddress1: '',
            physicalAddress2: '',
            physicalSuburb: '',
            physicalAddressCode: '',
            regionName: '',
            hcpType: hcpType,
            discipline: '',
            subRegionName: '',
            cellNumber: '',
            phoneNumber: '',
            market: market,
            governmentOfficial: governmentOffical,
            healthCareProfessional: true,
            agreetoTermsOfUse: true,
            userRole: 'User',
        };
        return userProfileRef.set(userProfile, { merge: true });
    }

    // getUserProfiles(searchString = '', pageNumber = 0, pageSize = 500): Observable<UserProfile[]> {
    //     return this.db
    //         .collection('userProfile', (ref) =>
    //             ref
    //                 .orderBy('title')
    //                 .limit(pageSize)
    //                 .startAfter(pageNumber * pageSize)
    //         )
    //         .snapshotChanges()
    //         .pipe(map((snaps) => convertSnaps<UserProfile[]>(snaps)));
    // }

    getUserProfiles(searchString = '', pageNumber = 0, pageSize = 2000): Observable<UserProfile[]> {
        return this.db
            .collection<UserProfile>('userProfile', (ref) => {
                let query: Query = ref;
                query = query
                    .orderBy('lastName', 'asc')
                    .limit(pageSize)
                    .startAfter(pageNumber * pageSize);
                if (searchString) {
                    query = query.where('lastName', '>=', searchString).where('lastName', '<=', searchString + '~');
                }
                return query;
            })
            .valueChanges({ idField: 'id' });
    }

    public handleError(error) {
        this.snackBar.open(error, 'close', { duration: 6000, panelClass: 'red-snackbar' });
    }
}
