<div class="content">
    <p><strong>Cookies and Privacy Policy</strong></p>

    <p class="sub-header">
        Effective March 27, 2017
    </p>

    <p>
        Pfizer, the company behind <a href="http://vcmehub.com">http://vcmehub.com</a> <strong>(“Site”)</strong>,
        respects your privacy and understands that health is a very personal and private subject. This Privacy Policy
        describes the personal information Pfizer collects about you on the Site; how we use that information; how we
        protect it; and the choices you may make with respect to your personal information. When we refer to ourselves
        as <strong>"we"</strong> or <strong>"Pfizer,"</strong> we mean Pfizer Laboratories Proprietary Limited and
        Pfizer Laboratories PFE Proprietary Limited (both separately and collectively). Your visit to the Site is
        subject to this Privacy Policy and to our Terms of Use.
    </p>

    <p>
        Our Site may contain links to third-party websites/ content/ services that are not owned or controlled by
        Pfizer. Pfizer is not responsible for how these properties operate or treat your personal information so we
        recommend that you read the privacy policies and terms associated with these third party properties carefully.
    </p>

    <p>
        By continuing to use this website you agree to our Terms of Use, Privacy Policy and that we may "collect,
        collate, process and/or store" your personal information (as defined in as defined in Protection of Personal
        Information Act 4 of 2013) for, amongst other things, (i) the purposes of providing you with access to the
        website and the website content; and (ii) for any of the purposes listed in this Site.
    </p>

    <p class="sub-header">
        Information We Collect and Us
    </p>

    <p>
        We collect personal information you choose to provide, e.g., through registrations, applications and surveys,
        and in connection with your inquiries. In addition, we may gather information about you automatically through
        your use of the Site, e.g., your IP address and how you navigate our Site. See also, the Section below on
        Cookies and Other Tools.
    </p>

    <p class="sub-header">
        How We Use Personal Information
    </p>

    <p>
        We may use your personal information in the following ways:
    </p>

    <p class="sub-header">
        To Serve You:
    </p>

    <p>
        We may use your personal information to respond to your inquiries and provide the products and services you
        request. We also use your information to deliver the content and services we believe you will find the most
        relevant. For example, a female user may receive information on products targeted at women that a male user will
        not see; a user who we know is a healthcare professional who treats arthritis may receive information relating
        to new arthritis therapies. We will also use your information to provide customer service and support.
    </p>

    <p class="sub-header">
        To Serve Third Parties You Ask Us to Serve:
    </p>

    <p>
        Our websites may offer various tools and functionality. Some of these are provided by Pfizer while others are
        provided by third parties. For example, Pfizer may provide functionality on the Site that will allow you to
        forward and share certain content with a friend or colleague. In addition, the Site may also offer third party
        sharing functionality such as third party social media widgets/tools/buttons. If you choose to use Pfizer
        sharing functionality to refer someone to our content, products or services, we will use your information and
        that of the individual you are sharing with to send the content or link you request, but we do not access, store
        or use the information relating to the individual you wish to share with other than in connection with your
        request. If you use third party sharing functionality, including social media widgets/tools/buttons, such use is
        subject to the third party’s privacy policy and terms. As with all links to non-Pfizer
        websites/content/services, we recommend that you read the privacy policies and terms associated with these third
        party properties carefully.
    </p>

    <p class="sub-header">
        To Validate Your Ability to Access / Use Certain Products, Services and Information:
    </p>

    <p>
        Some products, services and information we offer may only be suitable for/ accessible to individuals meeting
        certain eligibility or other criteria. In such cases, we may verify that you meet such criteria. For example,
        certain information relating to our products is only intended for and accessible to licensed healthcare
        professionals and we may use information we collect directly from you and from outside sources to validate your
        licensure and eligibility to view such information.
    </p>

    <p class="sub-header">
        In Accordance with Special Program Terms:
    </p>

    <p>
        On occasion, Pfizer may offer special programs and activities via the Site
        <strong>(“Special Programs”)</strong> that have specific terms, privacy notices and/or informed consent forms
        that explain how any personal information you provide will be processed in connection with the Special Program
        <strong>(“Special Program Terms”)</strong>. Pfizer will honour those Special Program Terms with respect to your
        personal information. Some of these Special Programs may involve sharing information with a third party and
        Pfizer will provide you with notice of this. You may be offered the opportunity to agree to or opt-out of the
        sharing of your information with such third party. But, in some instances, it may be necessary to agree to
        sharing with a third party in order to participate in a particular Special Program. If you consent/do not
        opt-out, we will share your information with the third party; and the personal information you provide may be
        used by the third party in accordance with the Special Program Terms and, unless provided otherwise in such
        Special Program Terms, for their own purposes in accordance with their own policies/terms. We strongly suggest
        you review both the Special Program Terms and any applicable third party policies/ terms before participating.
    </p>

    <p class="sub-header">
        To Improve Products and Services and Protect Patients and Consumers:
    </p>

    <p>
        We use the information you provide to better understand how our products and services impact you and those you
        care for and to further develop and improve our products and services. For example, you may be invited to
        participate in a survey relating to our products or services; healthcare professionals may be invited to
        participate in various programs or panels of experts. In addition, we use the information you provide to comply
        with our regulatory monitoring and reporting obligations including those related to adverse events, product
        complaints and patient safety.
    </p>

    <p class="sub-header">
        In Aggregated or De-identified Form:
    </p>

    <p>
        We may aggregate and/or de-identify data about visitors to our Site for various business purposes including
        product and service development and improvement activities.
    </p>

    <p class="sub-header">
        How We Share Your Personal Information
    </p>

    <p>
        Pfizer may share your personal information as follows:
    </p>

    <p class="sub-header">
        In Connection with our service
    </p>

    <p>
        For one or more of the above purposes, Pfizer may disclose personal information to:
    </p>

    <ul>
        <li>
            its affiliates within or outside of South Africa, in which case your personal information will be collected,
            used, disclosed, managed and stored in accordance with this policy; and
        </li>
        <li>
            third parties within or outside of South Africa – see the With Service Providers and Business Partners
            section; and
        </li>
        <li>
            regulatory authorities, within or outside of South Africa, and ethics committees as part of a clinical trial
            evaluation, monitoring or inspection process.
        </li>
    </ul>

    <p class="sub-header">
        In Connection with Business Transfers:
    </p>

    <p>
        If we sell or transfer a business unit (such as a subsidiary) or an asset (such as a website) to another
        company, we will share your personal information with such company and require such company to use and protect
        your personal information consistent with this Privacy Policy.
    </p>

    <p class="sub-header">
        With Service Providers and Business Partners:
    </p>

    <p>
        We may retain other companies and individuals to perform services on our behalf and we may collaborate with
        other companies and individuals with respect to particular products or services (collectively,
        <strong>“Providers”</strong>). Examples of Providers include data analysis firms, credit card processing
        companies, customer service and support providers, email and SMS vendors, web hosting and development companies
        and fulfillment companies. Providers also include our co-promote partners for products that we jointly develop
        and/or market with other companies. Some Providers may collect information on our behalf on our Site. These
        third parties may be provided with access to personal information needed to perform their functions, but they
        may not use such information other than on our behalf and subject to contracts that protect the confidentiality
        of the information. In addition, as noted above, we may share information with third parties in accordance with
        Special Program Terms unless you opt-out/do not consent.
    </p>

    <p class="sub-header">
        To Comply with Law/Protect:
    </p>

    <p>
        We reserve the right to disclose your personal information as required by law, when we believe disclosure is
        necessary to comply with a regulatory requirement, judicial proceeding, court order, or legal process served on
        us, or to protect the safety, rights, or property of our customers, the public or Pfizer.
    </p>

    <p class="sub-header">
        In the Aggregate/De-identified:
    </p>

    <p>
        Pfizer may also disclose aggregate or de-identified data that is not personally identifiable with third parties.
        Aggregate data is created by pooling information about individuals and describing the data in the aggregate,
        e.g., 20% of customers who clicked on the X page participated in the survey.
    </p>

    <p class="sub-header">
        Cookies and Other Tools
    </p>

    <p>
        Pfizer and its business partners collect information about you by using cookies, tracking pixels and other
        technologies (collectively, <strong>“Tools”</strong>). We use this information to better understand, customize
        and improve user experience with our websites, services and offerings. For example, we use web analytics
        services that leverage these Tools to help us to understand how visitors engage with and navigate our Site,
        e.g., how and when pages in a site are visited and by how many visitors. We are also able to offer our visitors
        a more customized, relevant experience on our sites using these Tools by delivering content and functionality
        based on your preferences and interests. If you have provided us with personal information, e.g., through a
        registration or a request for certain materials, we may associate this personal information with information
        gathered through the Tools. This allows us to offer increased personalization and functionality.
    </p>

    <p>
        Further as part of the Tools, we may use other tracking systems like pixel-tags. Pixel tags, sometimes called
        web beacons, are similar in function to cookies but because of their insignificant size, they are not visible.
        However, they are used to pass certain information to our servers to personalize our websites and to track your
        usage across other Pfizer websites. In addition, we may also use pixel tags in our HTML based emails.
    </p>

    <p>
        In addition, certain third parties may deliver cookies to your computer for a variety of reasons. For example,
        we sometimes use Google Analytics and/or Web Trend which are web analytics tools that help us understand how
        visitors engage with our websites. Any third party links or advertising on our site may also use cookies; you
        may receive these cookies by clicking on the link to the third party site or advertising. We do not control the
        collection or use of information by these third parties, and these third party cookies are not subject to this
        Privacy Policy. You should contact these companies directly if you have any questions about their collection
        and/or use of information.
    </p>

    <p>
        Your web browser can be set to allow you to control whether you will accept cookies, reject cookies, or to
        notify you each time a cookie is sent to your browser. If your browser is set to reject cookies, websites that
        are cookie-enabled will not recognize you when you return to the website, and some website functionality may be
        lost. The Help section of your browser may tell you how to prevent your browser from accepting cookies. If you
        are using a smartphone, set your preferences through the “Settings” app of your smartphone and/or the browser(s)
        app(s). There are also software products available that can manage cookies for you.
    </p>

    <p class="sub-header">
        Your Privacy Choices and Updates
    </p>

    <p>
        You may always update your personal information and your preferences. Limited communications may be necessary in
        order for you to participate in a particular program or service. Different programs and services may offer
        different phone numbers, links or preference managers that allow you to inform us of your updates and choices,
        including opting out of particular communications. These contact options are typically available on our Site or
        in the emails or texts we send.
    </p>

    <p class="sub-header">
        Transfer of Data to Other Countries
    </p>

    <p>
        Any information you provide to Pfizer through use of the Site may be stored and processed, transferred between
        and accessed from the Republic of South Africa and other countries which may not guarantee the same level of
        protection of personal information as the one in which you reside. However, Pfizer will handle your personal
        information in accordance with this Privacy Policy regardless of where your personal information is
        stored/accessed.
    </p>

    <p class="sub-header">
        Children's Privacy
    </p>

    <p>
        We are committed to protecting the privacy of children. We do not knowingly collect personal information from
        children.
    </p>

    <p class="sub-header">
        Contact Us
    </p>

    <p>
        Please contact us if you have any questions or concerns about your personal information or require assistance in
        managing your choices.
    </p>

    <p>
        Pfizer Laboratories (Pty) Ltd
    </p>

    <p>
        Privacy Officer
    </p>

    <p>
        P O Box 783720
    </p>

    <p>
        Sandton, 2146
    </p>

    <p>
        South Africa
    </p>

    <p class="sub-header">
        Updates to Our Privacy Policy
    </p>

    <p>
        From time to time, we may update and timely post revisions to this Privacy Policy. Any changes will be effective
        immediately upon the posting of the revised Privacy Policy. We encourage you to periodically review this page
        for the latest information on our privacy practices. This Privacy Policy was updated as of the effective date
        listed above.
    </p>

    <p>
        Copyright © 2017 Pfizer. All rights reserved.
    </p>

    <p>
        The information provided in this site is intended for registered healthcare professionals of the Republic of
        South Africa only.
    </p>
    <div mat-dialog-actions>
        <button mat-raised-button class="mat-primary mb-1" (click)="close()">Close</button>
    </div>
</div>
