import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserProfileService } from './auth/user-profile.service';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';
// import { delay } from 'rxjs/operators';

interface IMenuItem {
    type: string; // Possible values: link/dropDown/icon/separator/extLink
    name?: string; // Used as display text for item and title for separator type
    state?: string; // Router state
    icon?: string; // Material icon name
    tooltip?: string; // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    highlight?: boolean;
    hidden?: boolean;
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
}
interface IChildItem {
    type?: string;
    name: string; // Display text
    state?: string; // Router state
    disabled?: boolean;
    highlight?: boolean;
    hidden?: boolean;
    icon?: string;
    sub?: IChildItem[];
}

interface IBadge {
    color: string; // primary/accent/warn/hex color codes(#fff000)
    value: string; // Display text
}

@Injectable()
export class NavigationService {
    iconMenu: IMenuItem[] = [
        {
            name: 'HUB',
            type: 'icon',
            tooltip: 'Hub',
            icon: 'home',
            state: 'hub',
        },
        {
            name: 'PROFILE',
            type: 'icon',
            tooltip: 'Profile',
            icon: 'person',
            state: 'user/profile',
        },
    ];

    // Icon menu TITLE at the very top of navigation.
    // This title will appear if any icon type item is present in menu.
    iconTypeMenuTitle = 'Frequently Accessed';
    // sets iconMenu as default;

    menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();
    isAdmin = false;

    constructor(
        private userProfileService: UserProfileService,
        private authService: AuthService,
        private router: Router
    ) {
        this.getUserProfile();
    }

    getUserProfile() {
        this.authService.currentUser$.subscribe((currentUser) => {
            this.userProfileService.fetchUserProfile(currentUser).subscribe((userProfile) => {
                if (!userProfile) {
                    this.isAdmin = false;
                } else {
                    if (userProfile.userRole === 'Super Admin' || userProfile.userRole === 'Admin') {
                        this.isAdmin = true;
                    } else {
                        this.isAdmin = false;
                    }
                }
                this.addAdminMenu();
            });
        });
    }

    addAdminMenu() {
        const adminMenu: IMenuItem[] = [
            {
                name: 'HUB',
                type: 'icon',
                tooltip: 'Hub',
                icon: 'home',
                state: 'hub',
            },
            {
                name: 'PROFILE',
                type: 'icon',
                tooltip: 'Profile',
                icon: 'person',
                state: 'user/profile',
            },
            {
                name: 'CERTIFICATES',
                type: 'icon',
                tooltip: 'My Certificates',
                icon: 'note_add',
                state: 'cpd/certificates',
            },
            {
                type: 'separator',
                name: 'Main Items',
            },
            {
                name: 'ADMIN',
                type: 'dropDown',
                tooltip: 'Admin',
                icon: 'security',
                state: '',
                hidden: !this.isAdmin,
                sub: [
                    { name: 'User Management', icon: 'contacts', type: 'link', state: 'admin/users' },
                    { name: 'Doctors DB', icon: 'contacts', type: 'link', state: 'admin/doctor' },
                    {
                        name: 'Presenter Management',
                        icon: 'supervisor_account',
                        type: 'link',
                        state: 'admin/presenter',
                    },
                    {
                        name: 'CPD Activity Management',
                        icon: 'question_answer',
                        type: 'link',
                        state: 'admin/questionnaires-admin',
                    },
                    { name: 'Reports', icon: 'contacts', type: 'link', state: 'admin/analytics' },
                    { name: 'Webinar', icon: 'video_call', type: 'link', state: 'admin/webinar' },
                    {
                        name: 'Certificate Background',
                        icon: 'developer_board',
                        type: 'link',
                        state: 'admin/certificate-background',
                    },
                    {
                        name: 'vCME LIVE',
                        type: 'link',
                        icon: 'burst_mode',
                        state: 'video-conference',
                    },
                ],
            },
            {
                name: 'Bulk Email',
                type: 'dropDown',
                tooltip: 'Email',
                icon: 'email',
                state: '',
                hidden: !this.isAdmin,
                sub: [
                    { name: 'Compose', icon: 'inbox', type: 'link', state: 'email/compose' },
                    { name: 'Mailing List', icon: 'list', type: 'link', state: 'email/mailing-list' },
                ],
            },
            {
                name: 'Content HUB',
                type: 'dropDown',
                tooltip: 'Content HUB',
                icon: 'dashboard',
                state: '',
                sub: [
                    { name: 'Clinical Accredited', state: 'hub/general' },
                    { name: 'Ethics Accredited', state: 'hub/medical-ethics' },
                    { name: 'Pharmacy Academy', state: 'hub/pharmacy-academy' },
                    { name: 'Knowledge Centre', state: 'hub/knowledge-centre' },
                    { name: 'Case Study', state: 'hub/case-study' },
                ],
            },
            {
                name: 'My vCMEHub',
                type: 'dropDown',
                tooltip: 'My vCMEHub',
                icon: 'library_books',
                state: '',
                sub: [
                    { name: 'My Profile', state: 'user/profile' },
                    { name: 'My Certificates', state: 'cpd/certificates' },
                    // { name: 'My Reports', state: 'cpd/certificates' },
                ],
            },
            {
                name: 'vCME LIVE Webinar',
                type: 'link',
                tooltip: 'vCME LIVE Webinar',
                icon: 'subscriptions',
                disabled: true,
                highlight: false, // change to true to highlight this menu item
                state: 'hub/webinar',
            },
            {
                name: 'HFD',
                type: 'link',
                tooltip: 'Hidden Faces of Diabetes',
                icon: 'supervisor_account',
                disabled: true,
                state: 'hub/hfd',
            },
            {
                name: 'Messages of Hope',
                type: 'link',
                tooltip: 'Messages of Hope',
                icon: 'looks',
                disabled: true,
                state: 'hub/messages of hope',
            },
            {
                name: 'Podcasts',
                type: 'link',
                tooltip: 'Podcasts',
                icon: 'perm_camera_mic',
                disabled: true,
                state: 'hub/podcast',
            },
            {
                name: 'Help',
                type: 'link',
                tooltip: 'vCME LIVE',
                icon: 'live_help',
                state: 'cpd/help',
            },
        ];
        this.menuItems.next(adminMenu);
    }

    // // Customizer component uses this method to change menu.
    // // You can remove this method and customizer component.
    // // Or you can customize this method to supply different menu for
    // // different user type.
    // publishNavigationChange(menuType: string) {
    //     switch (menuType) {
    //         case 'separator-menu':
    //             this.menuItems.next(this.separatorMenu);
    //             break;
    //         case 'icon-menu':
    //             this.menuItems.next(this.iconMenu);
    //             break;
    //         default:
    //             this.menuItems.next(this.plainMenu);
    //     }
    // }
}
