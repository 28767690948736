export const environment = {
    production: true,
    apiURL: 'developmentApi',
    firebaseConfig: {
        apiKey: 'AIzaSyAXBebMbUg274NIsOfNkdsxS7J_EZ61VBU',
        authDomain: 'vcmehub-qa.firebaseapp.com',
        databaseURL: 'https://vcmehub-qa.firebaseio.com',
        projectId: 'vcmehub-qa',
        storageBucket: 'gs://vcmehub-qa.appspot.com',
        messagingSenderId: '418128938889',
        appId: '1:418128938889:web:3139ea4a92092efe104358',
        measurementId: 'G-729DN0LDVB',
        storageBaseUrl: 'https://firebasestorage.googleapis.com/v0/b/vcmehub-qa.appspot.com/o/',
    },
    authFactory: {
        enableFirestoreSync: true, // enable/disable autosync users with firestore
        toastMessageOnAuthSuccess: false, // whether to open/show a snackbar message on auth success - default : true
        toastMessageOnAuthError: false, // whether to open/show a snackbar message on auth error - default : true
        authGuardFallbackURL: '/home', // url for unauthenticated users - to use in combination with canActivate feature on a route
        authGuardLoggedInURL: '/hub', // url for authenticated users - to use in combination with canActivate feature on a route
        passwordMaxLength: 60, // `min/max` input parameters in components should be within this range.
        passwordMinLength: 6, // Password length min/max in forms independently of each componenet min/max.
        // Same as password but for the name
        nameMaxLength: 50,
        nameMinLength: 2,
        // If set, sign-in/up form is not available until email has been verified.
        // Plus protected routes are still protected even though user is connected.
        guardProtectedRoutesUntilEmailIsVerified: true,
        enableEmailVerification: true, // default: true
    },
    // agora: {
    //     appId: 'a00ca74ed2dd4cf188508196643baad5', // our app id
    // },
    vcmeLiveUrl: 'https://vcme-live.web.app/#/',
    // vcmeLiveUrl: 'http://localhost:3000/#/',
    // agoraToken: 'NWE5NzllOTFkYWNlNGUyMThmMWMzZDMwNTdkODdjMzc6MzUxMTZiYzhhYWZlNDhlMmEzMzA2MzE4MjVkY2E4Mjg=',
    // agoraBackendIntegrationUrl: 'http://localhost:3001',
    // agoraBackendIntegrationUrl: 'https://vcme-live.web.app',
};
