import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { convertSnaps } from 'app/shared/helpers/db-utils';
import { CompletedQuestionnaire, Questionnaire, SavedResponse } from 'app/models/questionnaire.model';
import { UserProfile } from 'app/models/user-profile.model';

@Injectable()
export class QuestionnaireAdminService {
    selectedQuestionnaire: Questionnaire;

    constructor(private firestore: AngularFirestore) {}

    // setQuestionnaire(questionnaire: Questionnaire) {
    //     this.selectedQuestionnaire = questionnaire;
    // }

    // getQuestionnaire() {
    //     return this.selectedQuestionnaire;
    // }

    getQuestionnaires(): Observable<Questionnaire[]> {
        return this.firestore
            .collection('questionnaires')
            .snapshotChanges()
            .pipe(map((snaps) => convertSnaps<Questionnaire[]>(snaps)));
    }

    getActiveQuestionnaires(filter, userProfile: UserProfile): Observable<Questionnaire[]> {
        return this.firestore
            .collection('questionnaires')
            .snapshotChanges()
            .pipe(
                map((snaps) => {
                    const questionnaires = convertSnaps<Questionnaire[]>(snaps)
                        .filter((q) => q.isActive === true)
                        .filter((m) => m.markets.find((c) => c === userProfile.market))
                        .filter((h) => h.hcpTypes.find((h) => h === userProfile.hcpType));
                    if (filter) {
                        filter = filter.replace('-', ' ');
                        return questionnaires.filter((f) => f.activityType.toLowerCase() === filter.toLowerCase());
                    }
                    return questionnaires;
                })
            );
    }

    createQuestionnaire(questionnaire: Questionnaire) {
        const questionnaireDocRef = this.firestore.collection('questionnaires').doc(questionnaire.questionnaireId);
        return questionnaireDocRef.set(JSON.parse(JSON.stringify(questionnaire)));
        // return this.firestore.collection('questionnaires').add(JSON.parse(JSON.stringify(questionnaire))).then(
        //     () => {
        //         console.log('questionnaire added');
        //     }
        // );
    }

    updateQuestionnaire(questionnaire: Questionnaire) {
        return this.firestore
            .doc('questionnaires/' + questionnaire.questionnaireId)
            .update(JSON.parse(JSON.stringify(questionnaire)));
    }

    deletePolicy(questionnaires: string) {
        return this.firestore.doc('questionnaires/' + questionnaires).delete();
    }

    recordCompletedQuestionnaire(completedQuestionnaire: CompletedQuestionnaire) {
        const questionnaireDocRef = this.firestore
            .collection('completedQuestionnaire')
            .doc(completedQuestionnaire.completedQuestionnaireId);
        return questionnaireDocRef.set(JSON.parse(JSON.stringify(completedQuestionnaire)));
    }

    getAllCompletedQuestionnaires(): Observable<CompletedQuestionnaire[]> {
        return this.firestore
            .collection('completedQuestionnaire')
            .snapshotChanges()
            .pipe(map((snaps) => convertSnaps<CompletedQuestionnaire[]>(snaps)));
    }

    // fixCompletedQuestionnaires() {
    //     let completed;
    //     this.getAllCompletedQuestionnaires().subscribe((c) => {
    //         // console.log(c);
    //         let count = 0;
    //         completed = c.filter((f) => f.market === 'Test country');
    //         // console.log(completed.length);
    //         completed.forEach((q) => {
    //             q.market = 'South Africa';
    //             const questionnaireDocRef = this.firestore
    //                 .collection('completedQuestionnaire')
    //                 .doc(q.completedQuestionnaireId);
    //             questionnaireDocRef.update(q);
    //             console.log(count++);
    //             // this.firestore.doc('completedQuestionnaire/' + presenter.id).update(presenter);
    //             // this.recordCompletedQuestionnaire(q);
    //         });
    //     });
    // }

    saveResponses(savedResponse: SavedResponse) {
        const questionnaireDocRef = this.firestore
            .collection('savedResponse')
            .doc(`${savedResponse.questionnaireId}-${savedResponse.userId}`);
        return questionnaireDocRef.set(JSON.parse(JSON.stringify(savedResponse)));
    }

    getSavedResponse(questionId: string, userId: string) {
        return this.firestore
            .collection('savedResponse')
            .snapshotChanges()
            .pipe(
                map((snaps) =>
                    convertSnaps<SavedResponse[]>(snaps).filter(
                        (s) => s.userId === userId && s.questionnaireId === questionId
                    )
                )
            );
    }

    deleteResponse(savedResponse: SavedResponse) {
        return this.firestore.doc(`savedResponse/${savedResponse.questionnaireId}-${savedResponse.userId}`).delete();
    }
}
