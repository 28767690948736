import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AuthService } from 'app/services/auth/auth.service';
import { LegalService } from 'app/services/legal.service';

@Component({
    selector: 'app-bottom-sheet-share',
    templateUrl: './bottom-sheet-share.component.html',
    styleUrls: ['./bottom-sheet-share.component.scss'],
})
export class BottomSheetShareComponent implements OnInit {
    market;
    constructor(
        private bottomSheetRef: MatBottomSheetRef<BottomSheetShareComponent>,
        private authService: AuthService,
        private legalService: LegalService
    ) {}

    ngOnInit() {
        this.getUserProfile();
    }

    closeBottomSheet() {
        this.bottomSheetRef.dismiss();
    }

    getUserProfile() {
        if (this.authService.userProfile$) {
            this.authService.userProfile$.subscribe((userProfile) => {
                this.market = userProfile.market;
            });
        }
    }

    openAdverseEventReporting() {
        this.legalService.openAdverseEventReporting(this.market);
    }

    openTermsOfUse() {
        this.legalService.openTermsOfUse(this.market);
    }

    openPrivacyPolicy() {
        this.legalService.openPrivacyPolicy(this.market);
    }

    openDisclaimer() {
        this.legalService.openDisclaimer(this.market);
    }

    openTechnicalAssistance() {
        this.legalService.openTechnicalAssistance(this.market);
    }

    showDisclaimer() {
        return this.legalService.showDisclaimer(this.market);
    }

    showTechnicalAssistance() {
        return this.legalService.showTechnicalAssistance(this.market);
    }

    showAdverseReporting() {
        return this.legalService.showAdverseReporting(this.market);
    }

    showPrivacyPolicy() {
        return this.legalService.showPrivacyPolicy(this.market);
    }
}
