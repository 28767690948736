import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TermsOfUseComponent } from 'app/legal-docs/terms-of-use/terms-of-use.component';
import { TermsOfUseGhanaComponent } from 'app/legal-docs/terms-of-use-ghana/terms-of-use-ghana.component';
import { TermsOfUseKenyaComponent } from 'app/legal-docs/terms-of-use-kenya/terms-of-use-kenya.component';
import { TermsOfUseIndiaComponent } from 'app/legal-docs/terms-of-use-india/terms-of-use-india.component';
import { TermsOfUseNigeriaComponent } from 'app/legal-docs/terms-of-use-nigeria/terms-of-use-nigeria.component';
import { TermsOfUseFWAComponent } from 'app/legal-docs/terms-of-use-fwa/terms-of-use-fwa.component';
import { PrivacyPolicyIndiaComponent } from 'app/legal-docs/privacy-policy-india/privacy-policy-india.component';
import { PrivacyPolicyComponent } from 'app/legal-docs/privacy-policy/privacy-policy.component';
import { DisclaimerComponent } from 'app/legal-docs/disclaimer/disclaimer.component';
import { DisclaimerIndiaComponent } from 'app/legal-docs/disclaimer-india/disclaimer-india.component';
import { DisclaimerPakistanComponent } from 'app/legal-docs/disclaimer-pakistan/disclaimer-pakistan.component';
import { TechnicalAssistanceComponent } from 'app/legal-docs/technical-assistance/technical-assistance.component';
import { AdverseEventReportingComponent } from 'app/legal-docs/adverse-event-reporting/adverse-event-reporting.component';
import { TermsOfUsePakistanComponent } from 'app/legal-docs/terms-of-use-pakistan/terms-of-use-pakistan.component';

@Injectable({
    providedIn: 'root',
})
export class LegalService {
    constructor(private db: AngularFirestore, private dialog: MatDialog) {}

    openTermsOfUse(market: string) {
        // switch (market) {
        //     case 'South Africa':
        //         this.openDialog(TermsOfUseComponent);
        //         break;
        //     case 'Ghana':
        //         this.openDialog(TermsOfUseGhanaComponent);
        //         break;
        //     case 'Kenya':
        //         this.openDialog(TermsOfUseKenyaComponent);
        //         break;
        //     case 'India':
        //         this.openDialog(TermsOfUseIndiaComponent);
        //         break;
        //     case 'Pakistan':
        //         // window.open('https://www.pfizer.com.pk/content/website-terms-use', '_blank');
        //         this.openDialog(TermsOfUsePakistanComponent);
        //         break;
        //     case 'Nigeria':
        //         this.openDialog(TermsOfUseNigeriaComponent);
        //         break;
        //     default:
        //         this.openDialog(TermsOfUseFWAComponent);
        //         break;
        // }
        window.open('https://www.viatris.com/en/Cookie-Notice', '_blank');
    }

    openAdverseEventReporting(market: string) {
        switch (market) {
            case 'South Africa':
                this.openDialog(AdverseEventReportingComponent);
                break;
            case 'Ghana':
                window.open('mailto: NGA.AEReporting@pfizer.com', '_blank');
                break;
            case 'Kenya':
                window.open('mailto: NGA.AEReporting@pfizer.com', '_blank');
                break;
            default:
                window.open('https://www.pfizermedicalinformationng.com/', '_blank');
                break;
        }
    }

    openPrivacyPolicy(market: string) {
        // switch (market) {
        //     case 'Pakistan':
        //         window.open('https://www.pfizer.com.pk/content/website-privacy-policy', '_blank');
        //         break;
        //     case 'India':
        //         this.openDialog(PrivacyPolicyIndiaComponent);
        //         break;
        //     case 'South Africa':
        //         this.openDialog(PrivacyPolicyComponent);
        //         break;
        // }
        window.open('https://www.viatris.com/en/privacy-policy', '_blank');
    }

    openDisclaimer(market: string) {
        switch (market) {
            case 'South Africa':
                this.openDialog(DisclaimerComponent);
                break;
            case 'India':
                this.openDialog(DisclaimerIndiaComponent);
                break;
            case 'Pakistan':
                this.openDialog(DisclaimerPakistanComponent);
                break;
        }
    }

    openTechnicalAssistance(market: string) {
        this.openDialog(TechnicalAssistanceComponent);
    }

    openDialog(dialogComponent) {
        const dialogRef: MatDialogRef<any> = this.dialog.open(dialogComponent, {
            height: '70%',
            width: '80%',
            disableClose: false,
            autoFocus: false,
        });
    }

    showDisclaimer(market: string) {
        switch (market) {
            case 'South Africa':
                return true;
                break;
            case 'India':
                return true;
                break;
            case 'Pakistan':
                return true;
                break;
            default:
                return false;
                break;
        }
    }

    showTechnicalAssistance(market: string) {
        switch (market) {
            case 'India':
                return false;
                break;
            case 'Pakistan':
                return false;
                break;
            default:
                return true;
                break;
        }
    }

    showAdverseReporting(market: string) {
        switch (market) {
            case 'South Africa':
                return true;
                break;
            case 'Nigeria':
                return true;
                break;
            case 'Kenya':
                return true;
                break;
            case 'Ghana':
                return true;
            case 'India':
                return false;
                break;
            case 'Pakistan':
                return false;
                break;
            default:
                return false;
                break;
        }
    }

    showPrivacyPolicy(market: string) {
        switch (market) {
            case 'South Africa':
                return true;
                break;
            case 'India':
                return true;
                break;
            case 'Pakistan':
                return true;
                break;
            default:
                return false;
                break;
        }
    }
}
