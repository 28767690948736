import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TermsOfUseComponent } from 'app/legal-docs/terms-of-use/terms-of-use.component';
import { AdverseEventReportingComponent } from 'app/legal-docs/adverse-event-reporting/adverse-event-reporting.component';
import { PrivacyPolicyComponent } from 'app/legal-docs/privacy-policy/privacy-policy.component';
import { DisclaimerComponent } from 'app/legal-docs/disclaimer/disclaimer.component';
import { UserProfileService } from 'app/services/auth/user-profile.service';
import { AuthService } from 'app/services/auth/auth.service';
import { TermsOfUseNigeriaComponent } from 'app/legal-docs/terms-of-use-nigeria/terms-of-use-nigeria.component';
import { TechnicalAssistanceComponent } from 'app/legal-docs/technical-assistance/technical-assistance.component';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { BottomSheetShareComponent } from '../bottom-sheet-share/bottom-sheet-share.component';
import { UserProfile } from 'app/models/user-profile.model';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
    // tslint:disable no-input-rename
    @Input('items') public menuItems: any[] = [];
    @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
    @Input('iconMenuTitle') public iconTypeMenuTitle: string;

    userProfile: UserProfile;
    // private dialog: MatDialog, private authService: AuthService
    constructor(private matBottomSheet: MatBottomSheet, private authService: AuthService) {}
    ngOnInit() {
        this.getUserProfile();
    }

    getUserProfile() {
        if (this.authService.userProfile$) {
            this.authService.userProfile$.subscribe((userProfile) => {
                this.userProfile = userProfile;
            });
        }
    }

    // Only for demo purpose
    addMenuItem() {
        this.menuItems.push({
            name: 'ITEM',
            type: 'dropDown',
            tooltip: 'Item',
            icon: 'done',
            state: 'material',
            sub: [
                { name: 'SUBITEM', state: 'cards' },
                { name: 'SUBITEM', state: 'buttons' },
            ],
        });
    }

    openBottomSheet(): void {
        this.matBottomSheet.open(BottomSheetShareComponent);
        // , { disableClose: true }
    }

    disableItemforIndiaAndPakistan(disabled: boolean) {
        if (this.userProfile) {
            switch (this.userProfile.market) {
                case 'South Africa':
                    return false;
                    break;
                case 'India':
                    return disabled;
                    break;
                case 'Pakistan':
                    return disabled;
                    break;
                default:
                    return false;
                    break;
            }
        }
    }

    menuItemClass(menuItem) {
        let itemClass = '';
        if (this.disableItemforIndiaAndPakistan(menuItem.disabled)) {
            itemClass += 'disabled ';
        }
        if (menuItem.highlight) {
            itemClass += 'highlight ';
        }
        return itemClass;
    }
}
