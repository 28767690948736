<mat-card class="card-container p-1" [@animate]="{ value: '*', params: { scale: '.9', delay: '300ms' } }">
    <mat-card-content>
        <img
            class="card-thumbnail"
            [src]="questionnaire.activityThumbnailUrl ? questionnaire.activityThumbnailUrl : quizzIcon"
            alt=""
        />

        <img *ngIf="questionnaire.newFlag"
            class="card-new-flag"
            src="assets/images/new-flag.png"
            alt=""
        />

        <h2 class="card-title mat-title" [innerHtml]="questionnaire.title | safe:'html'"></h2>

        <div>{{ getVideoDuration(questionnaire) }}</div>
        <mat-icon
            class="card-icon"
            [ngClass]="
                getActivityColor(getExpiredStatus(questionnaire.questionnaireStatus, questionnaire.expiryDate), false)
            "
            >{{ questionnaire.questionnaireStatus == 'Completed' ? 'check_circle' : 'brightness_1' }}</mat-icon
        >
        <div class="card-activity-type">{{ questionnaire.activityType }}</div>

        <button
            (click)="openVideo(questionnaire)"
            mat-flat-button
            mat-ripple
            class="card-button"
            [ngClass]="getActivityColor(getExpiredStatus(questionnaire.questionnaireStatus, questionnaire.expiryDate))"
        >
            {{ getExpiredStatus(questionnaire.questionnaireStatus, questionnaire.expiryDate) }}
        </button>

        <div *ngIf="showCpdPoints(questionnaire.cpdPointAccreditation)" class="card-points">
            <b>CPD Points:</b> {{ questionnaire.cpdPointAccreditation }}
        </div>
        <div
            *ngIf="questionnaire.activityType !== 'Webinar'"
            [class]="
                showCpdPoints(questionnaire.cpdPointAccreditation)
                    ? 'card-expiry-date'
                    : 'card-expiry-date card-expiry-date-center'
            "
        >
            <b>Expiry Date:</b>
            <span [class]="closeToExpire(questionnaire.expiryDate)">{{
                questionnaire.expiryDate | date: ' dd MMM yyyy'
            }}</span>
        </div>
        <div *ngIf="questionnaire.activityType === 'Webinar'" class="card-expiry-date">
            <span>{{ questionnaire.webinarStartDateTime }}</span>
        </div>
        <!-- 1st Presenter -->
        <img
            [class]="questionnaire.presenter2 ? 'bio-image1' : 'bio-image-center'"
            [src]="questionnaire.presenter.photoURL ? questionnaire.presenter.photoURL : 'assets/images/avatar.jpg'"
            alt=""
            height="120px"
            width="120px"
            (click)="openDialog(questionnaire.presenter)"
        />
        <div [class]="questionnaire.presenter2 ? 'bio-name1' : 'bio-name-center'">
            {{ questionnaire.presenter.title }} {{ questionnaire.presenter.firstName }}
            {{ questionnaire.presenter.lastName }}
        </div>

        <!-- 2nd Presenter -->
        <img
            *ngIf="questionnaire.presenter2"
            class="bio-image2"
            [src]="questionnaire.presenter2.photoURL ? questionnaire.presenter2.photoURL : 'assets/images/avatar.jpg'"
            alt=""
            height="120px"
            width="120px"
            (click)="openDialog(questionnaire.presenter2)"
        />
        <div class="bio-name2" *ngIf="questionnaire.presenter2">
            {{ questionnaire.presenter2?.title }} {{ questionnaire.presenter2?.firstName }}
            {{ questionnaire.presenter2?.lastName }}
        </div>

        <!-- <button class="card-button" mat-raised-button color="primary" (click)="openVideo(questionnaire)">
                    Open
                </button> -->
    </mat-card-content>
</mat-card>
