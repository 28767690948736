import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/services/auth/auth.service';
import { ThemeService } from 'app/services/theme.service';
import { LayoutService } from 'app/services/layout.service';
import { UserProfileService } from 'app/services/auth/user-profile.service';
import { UserProfile } from 'app/models/user-profile.model';
import { CountryLanguageService } from 'app/services/country-language.service';
import { HcpTypeService } from 'app/services/hcp-type.service';

@Component({
    selector: 'app-header-side',
    templateUrl: './header-side.template.html',
    styleUrls: ['./header-side.component.scss'],
})
export class HeaderSideComponent implements OnInit {
    @Input() notificPanel;
    public availableLangs = this.countryLanguageService.getCountryLanguage();

    currentLang;

    // public userProfile: UserProfile;
    public photoURL: string;
    public market: string;
    public hcpTypes$;
    public showMarkets = false;
    public userProfile: UserProfile;
    public egretThemes;
    public layoutConf: any;
    constructor(
        private themeService: ThemeService,
        private layout: LayoutService,
        public translate: TranslateService,
        private authService: AuthService,
        private userProfileService: UserProfileService,
        private countryLanguageService: CountryLanguageService,
        private hcpTypeService: HcpTypeService
    ) {}

    ngOnInit() {
        this.getUserProfile();
        this.hcpTypes$ = this.hcpTypeService.getHcpTypes();
        this.egretThemes = this.themeService.egretThemes;
        this.layoutConf = this.layout.layoutConf;
    }

    getUserProfile() {
        if (this.authService.userProfile$) {
            this.authService.userProfile$.subscribe((userProfile) => {
                this.userProfile = userProfile;
                if (this.userProfile.userRole === 'Super Admin') {
                    this.showMarkets = true;
                }
                this.market = userProfile.market;
                this.photoURL = userProfile.photoURL;
                this.currentLang = this.countryLanguageService.getCurrentLanguage(userProfile);
                this.translate.use(this.currentLang.code);
            });
        }
    }

    updateMarket(market) {
        if (this.userProfile.userRole === 'Super Admin') {
            this.userProfile.market = market.name;
            this.userProfileService.updateUserProfile(this.userProfile).then(() => this.getUserProfile());
        }
    }

    updateHcpType(hcpType) {
        if (this.userProfile.userRole === 'Super Admin') {
            this.userProfile.hcpType = hcpType.name;
            this.userProfileService.updateUserProfile(this.userProfile).then(() => this.getUserProfile());
        }
    }

    setLang(lng) {
        this.currentLang = lng;
        this.translate.use(lng.code);
    }

    changeTheme() {
        // this.themeService.changeTheme(theme);
    }

    toggleNotific() {
        this.notificPanel.toggle();
    }

    toggleSidenav() {
        if (this.layoutConf.sidebarStyle === 'closed') {
            return this.layout.publishLayoutChange({
                sidebarStyle: 'full',
            });
        }
        this.layout.publishLayoutChange({
            sidebarStyle: 'closed',
        });
    }

    toggleCollapse() {
        // compact --> full
        if (this.layoutConf.sidebarStyle === 'compact') {
            return this.layout.publishLayoutChange(
                {
                    sidebarStyle: 'full',
                    sidebarCompactToggle: false,
                },
                { transitionClass: true }
            );
        }

        // * --> compact
        this.layout.publishLayoutChange(
            {
                sidebarStyle: 'compact',
                sidebarCompactToggle: true,
            },
            { transitionClass: true }
        );
    }

    onSearch() {
        //   console.log(e)
    }

    signOut() {
        this.authService.signOut();
    }
}
